#preloader,
.preloader {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    right: 0;
    background: #a2a0a036;
    display: none;
}

#spinner_loader,
.spinner_loader {
    text-align: center;
    overflow: auto;
    margin: auto;
    position: absolute;
    left: calc(50% - 50px);
    border: 12px solid #ffffff;
    border-top: 12px solid rgb(var(--vamc-primary-300));
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 2s linear infinite;
}

.full-width #spinner_loader,
.full-width .spinner_loader {
    position: fixed;
    top: calc(50% - 50px);
}

.responsive .spinner_loader,
.responsive #spinner_loader {
    width: 100%;
    height: 100%;
    max-height: 70px;
    max-width: 70px;
    margin: -35px 0 0 -35px;
    left: 50%;
    top: 50%;
}

.responsive {
    overflow: hidden;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}