:root {
  --navigation-animation-offset: 10vh;
  --search-drawer-offset: 83px;
}

:root body[data-edtn="br"],
:root body[data-edtn="ev_br"],
:root body[data-edtn="ev_it"] {
  --search-drawer-offset: 128px;
}

:root body[data-edtn="tr"] {
  --search-drawer-offset: 143px;
}

@media only screen and (max-width: 1279px) {

  :root body[data-edtn="br"],
  :root body[data-edtn="ev_br"],
  :root body[data-edtn="ev_it"] {
    --search-drawer-offset: 149px;
  }

  :root body[data-edtn="br"],
  :root body[data-edtn="ev_br"] {
    --search-drawer-offset: 128px;
  }

  :root body[data-edtn="tr"] {
    --search-drawer-offset: 83px;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --search-drawer-offset: 56px;
  }

  :root body[data-edtn="br"],
  :root body[data-edtn="ev_br"],
  :root body[data-edtn="ev_it"] {
    --search-drawer-offset: 101px;
  }

  :root body[data-edtn="tr"] {
    --search-drawer-offset: 56px;
  }
}

@media only screen {
  .m1-drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 375px;
    background: rgb(var(--vamc-fill-soft-300));
    color: rgb(var(--vamc-text-contrast-soft));
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    z-index: 100;
  }

  .left-to-right.disabled,
  .right-to-left.disabled {
    display: none;
  }

  .m1-drawer.right-to-left {
    left: 0;
    right: auto;
  }

  .m1-drawer.left-to-right {
    right: 0;
    left: auto;
  }

  .m1-drawer.right-to-left.done {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }

  .m1-drawer.left-to-right.done {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  .m1-drawer-overlay {
    position: fixed;
    left: 0;
    right: auto;
    top: 0;
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    z-index: 1;
  }

  .m1-drawer.enabled ~ .m1-drawer-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
  }

  .m1-drawer-close-button {
    width: 36px;
    height: 36px;
    line-height: 0;
    font-size: 0;
    position: absolute;
    left: auto;
    right: 15px;
    top: 13px;
    cursor: pointer;
    z-index: 100;
  }

  .m1-drawer-close-button svg {
    width: 36px;
    height: 36px;
    fill: rgb(var(--vamc-primary-700));
  }

  .m1-right-side .m1-drawer-close-button svg {
    fill: var(--white-color);
  }

  .m1-drawer.user-auth-menu {
    width: 375px;
    border-right: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-bottom: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-left: 1px solid rgb(var(--vamc-stroke-contrast-200));
    background: rgb(var(--vamc-fill-soft-200));
    box-shadow: 0px 419px 117px 0px rgba(0, 0, 0, 0.00), 0px 268px 107px 0px rgba(0, 0, 0, 0.01), 0px 151px 91px 0px rgba(0, 0, 0, 0.05), 0px 67px 67px 0px rgba(0, 0, 0, 0.09), 0px 17px 37px 0px rgba(0, 0, 0, 0.10);
  }

  .m1-drawer.user-auth-menu .m1-desktop-registration {
    position: static;
    pointer-events: all;
    opacity: 1;
    border: none;
    box-shadow: none;
    background: transparent;
    width: 100%;
  }

  .m1-drawer.user-auth-menu .m1-desktop-registration .desktop-reg-title {
    background: rgb(var(--vamc-fill-soft-500));
    background: rgb(var(--vamc-fill-soft-500));
    justify-content: space-between;
  }

  .m1-drawer.user-auth-menu .desktop-reg-title .reg-close-btn {
    display: inline-block;
    width: 36px;
    height: 36px;
  }

  .m1-drawer.user-auth-menu .desktop-reg-title .reg-close-btn svg,
  .desktop-reg-text svg {
    stroke: rgb(var(--vamc-primary-700));
  }

  .m1-drawer.m1-search-drawer {
    width: 375px;
    height: 70px;
    top: var(--search-drawer-offset);
    left: calc((100vw - 1240px)/2 * -1);
    padding: var(--vam-spacing-md-xs);

    border-right: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-bottom: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-left: 1px solid rgb(var(--vamc-stroke-contrast-200));
    background: rgb(var(--vamc-fill-soft-200));
    box-shadow: 0px 419px 117px 0px rgba(0, 0, 0, 0.00), 0px 268px 107px 0px rgba(0, 0, 0, 0.01), 0px 151px 91px 0px rgba(0, 0, 0, 0.05), 0px 67px 67px 0px rgba(0, 0, 0, 0.09), 0px 17px 37px 0px rgba(0, 0, 0, 0.10);
  }

  .m1-drawer.m1-search-drawer.enabled + .m1-drawer-overlay {
    height: calc(100% - var(--search-drawer-offset));
    top: var(--search-drawer-offset);
  }

  .m1-drawer.m1-search-drawer .m1-search-panel {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m1-drawer.m1-search-drawer #header_search_form {
    width: 100%;
    min-width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--vam-spacing-md-xs);
  }

  .m1-right-side div.m1-drawer.m1-search-drawer #header_search_form input.m1-search-panel-input {
    display: flex;
    width: 284px;
    height: 44px;
    padding: 0 var(--vam-spacing-sm-lg) 0 var(--vam-spacing-md-xs);

    border-radius: var(--vam-radius-6);
    border: 1px solid rgb(var(--vamc-stroke-soft-200));
    background: rgb(var(--vamc-fill-soft-100));

    color: rgb(var(--vamc-text-contrast-muted));
    color: rgb(var(--vamc-text-contrast-muted));
    font-family: var(--vam-comfort-label-medium-font-name);
    font-size: var(--vam-comfort-label-medium-font-size);
    font-weight: var(--vam-comfort-label-medium-font-weight);
    line-height: var(--vam-comfort-label-medium-font-line-height);
  }

  .m1-drawer.m1-search-drawer .m1-search-panel .m1-search-panel-button,
  .m1-search-panel .m1-search-panel-button {
    width: 47px;
    height: 42px;
    border-radius: 4px;
    background: rgb(var(--vamc-button-secondary-solid-fill));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
  }

  .m1-drawer.m1-search-drawer .m1-search-panel .m1-search-panel-button svg {
    /* fill: rgb(var(--vamc-text-contrast-ink)); */
    fill: var(--side-menu-light-bg);
  }
}

@media only screen {
  .m1-left-side,
  .m1-right-side {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: 9999999999;
    display: block;
  }

  .m1-left-side {
    left: 0;
    right: auto;
  }

  .m1-right-side {
    right: 0;
    left: auto;
  }

  .m1-left-side-box {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
  }

  .m1-left-side-box::-webkit-scrollbar {
      width: 2px;
  }

  .m1-left-side-box::-webkit-scrollbar-thumb {
    background-color: rgba(77, 73, 73, 0.5);
    border-radius: 2px;
  }

  .m1-menus-box {
    flex: 1;
  }

  .m1-extra-menus-box {
    padding: 18px var(--vam-spacing-md-md);
    border-bottom: 1px solid rgb(var(--vamc-stroke-contrast-300));
  }

  .theme-switcher-title {
    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-normal-label-large-font-name);
    font-size: var(--vam-normal-label-large-font-size);
    font-weight: var(--vam-normal-label-large-font-weight);
    line-height: var(--vam-normal-label-large-font-line-height);
    padding: var(--vam-spacing-md-md) var(--vam-spacing-md-md) 0;
  }

  .theme-switcher-title.edtn-switcher-title {
    padding: 0;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: ease-in 0.3s;
    overflow: hidden;

    padding: var(--vam-spacing-md-sm) var(--vam-spacing-md-md);
    border-bottom: 1px solid rgb(var(--vamc-stroke-contrast-300));
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color button {
    background-color: transparent;
    border: none;
    margin: 0;
    border-radius: 0;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color button span {
    color: rgb(var(--vamc-text-contrast-muted));
    font-family: var(--vam-normal-label-large-font-name);
    font-size: var(--vam-normal-label-large-font-size);
    font-weight: var(--vam-normal-label-large-font-weight);
    line-height: var(--vam-normal-label-large-font-line-height);
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color .active-switch span,
  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color #autocolorscheme.active-switch span {
    color: rgb(var(--vamc-text-contrast-soft));
  }

  .radio-switcher.radio-switcher_color.disabled:has(.active-switch ~ .active-switch) #darkcolorscheme.active-switch .theme-switch-icon,
  .radio-switcher.radio-switcher_color.disabled:has(.active-switch ~ .active-switch) #lightcolorscheme.active-switch .theme-switch-icon {
    border: 3px solid transparent;
  }

  .radio-switcher.radio-switcher_color.disabled:has(.active-switch ~ .active-switch) #darkcolorscheme.active-switch span,
  .radio-switcher.radio-switcher_color.disabled:has(.active-switch ~ .active-switch) #lightcolorscheme.active-switch span {
    color: rgb(var(--vamc-text-contrast-muted));
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color button .theme-switch-icon {
    padding: 6px;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color button svg {
    width: 49px;
    height: 49px;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color #darkcolorscheme {
    padding: 0;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color #lightcolorscheme {
    padding: 0;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color #autocolorscheme {
    padding: 0;
  }

  .m1-colorscheme-switcher .radio-switcher.radio-switcher_color button.active-switch .theme-switch-icon {
    border: 3px solid rgb(var(--vamc-stroke-contrast-100));
    border-radius: 10px;
  }
}

@media only screen {
  .m1-drawer.m1-editions-drawer {
    width: 375px;
    border-right: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-bottom: 1px solid rgb(var(--vamc-stroke-contrast-200));
    border-left: 1px solid rgb(var(--vamc-stroke-contrast-200));
    background: rgb(var(--vamc-fill-soft-200));
    box-shadow: 0px 419px 117px 0px rgba(0, 0, 0, 0.00), 0px 268px 107px 0px rgba(0, 0, 0, 0.01), 0px 151px 91px 0px rgba(0, 0, 0, 0.05), 0px 67px 67px 0px rgba(0, 0, 0, 0.09), 0px 17px 37px 0px rgba(0, 0, 0, 0.10);
  }

  .m1-editions-box {
    position: absolute;
    top: 94px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .m1-editions-label {
    background: rgb(var(--vamc-fill-soft-500));
    height: 94px;
    display: flex;
    flex-direction: column;
    padding: var(--vam-spacing-md-md);
    gap: var(--vam-spacing-md-sm);
    border-bottom: 1px solid rgb(var(--vamc-stroke-soft-300));
  }

  .m1-editions-label > span {
    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-title-small-font-name);
    font-size: var(--vam-comfort-title-small-font-size);
    font-weight: var(--vam-comfort-title-small-font-weight);
    line-height: var(--vam-normal-title-small-font-line-height);
  }

  .m1-drawer-close-cancel {
    color: rgb(var(--vamc-text-contrast-ink));
    font-family: var(--vam-comfort-label-medium-font-name);
    font-size: var(--vam-comfort-label-medium-font-size);
    font-weight: var(--vam-comfort-label-medium-font-weight);
    line-height: var(--vam-comfort-label-medium-font-line-height);

    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;

    border-radius: 6px;
    border: 2px solid rgb(var(--vamc-button-secondary-outline-stroke));
  }

  .m1-editions-switcher {
    cursor: pointer;
    transition: filter 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .m1-editions-switcher:hover {
    text-decoration: none;
  }

  .box-item-right .m1-editions-switcher.active{
    filter: opacity(0);
  }

  .m1-editions-switcher .edf {
    display: inline-block;
    width: 38px;
    height: 20px;
    background-size: 38px 910px;
  }

  .m1-editions-switcher .edf-230 {
    background-position: 0 -21px;
  }

  .m1-editions-switcher .edf-72 {
    background-position: 0 -269px;
  }

  .m1-editions-switcher .edf-106 {
    background-position: 0 -373px;
  }

  .m1-editions-switcher .edf-80 {
    background-position: 0 -393px;
  }

  .m1-editions-switcher .edf-30 {
    background-position: 0 -187px;
  }

  .m1-editions-switcher .edf-229 {
    background-position: 0 -228px;
  }

  .m1-editions-switcher .edf-190 {
    background-position: 0 -580px;
  }

  .m1-editions-switcher .edf-222 {
    background-position: 0 -849px;
  }

  .m1-editions-switcher .edf-132 {
    background-position: 0 -455px;
  }

  .m1-editions-switcher .edf-97 {
    background-position: 0 -869px;
  }

  .m1-editions-switcher .edf-10 {
    background-position: 0 -83px;
  }

  .m1-editions-switcher .edf-101 {
    background-position: 0 -890px;
  }

  .m1-editions-switcher .title {
    display: inline-block;
    text-transform: uppercase;
    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-label-large-font-name);
    font-size: var(--vam-comfort-label-large-font-size);
    font-weight: var(--vam-comfort-label-large-font-weight);
    line-height: var(--vam-comfort-label-large-font-line-height);
  }

  .m1-editions-switcher .ico-switcher {
    margin-left: auto;
    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-label-large-font-name);
    font-size: var(--vam-comfort-label-large-font-size);
    font-weight: var(--vam-comfort-label-large-font-weight);
    line-height: var(--vam-comfort-label-large-font-line-height);
    display: flex;
    align-items: center;
  }

  .m1-editions-switcher .ico-switcher svg {
    width: 20px;
    height: 20px;
    margin-top: -3px;
    fill: rgb(var(--vamc-text-contrast-soft));
  }

  .m1-editions-switcher-mobile .ico-switcher svg {
    fill: rgb(var(--vamc-text-contrast-soft));
  }

  .m1-editions-switcher.active .ico-switcher svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .m1-editions-box {
    padding: 0 var(--vam-spacing-md-md);
  }

  .m1-editions-list {
    padding: var(--vam-spacing-sm-lg) 0;
  }

  .m1-editions-list li {
    padding: 16px;
    border-bottom: 1px dashed rgb(var(--vamc-stroke-contrast-300));
  }

  .m1-editions-list li:last-child {
    border-bottom: none;
  }

  .m1-editions-list li > a {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: rgb(var(--vamc-text-contrast-ink));
    font-family: var(--vam-comfort-label-large-font-name);
    font-size: var(--vam-comfort-label-large-font-size);
    font-weight: var(--vam-comfort-label-large-font-weight);
    line-height: var(--vam-comfort-label-large-font-line-height);
  }

  .m1-editions-list li.current {
    display: none;
  }

  .m1-editions-list li.current > a {
    text-decoration: none;
    color: var(--main-link-color);
  }

  .m1-editions-list li > a > span {
    display: block;
    white-space: nowrap;
  }

  .m1-editions-list li > a > span.flags {
    line-height: 0;
    font-size: 0;
  }

  .m1-editions-list li > a > span.flags > .edf {
    margin-right: 5px;
  }

  .m1-left-side-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    min-height: 68px;
    display: flex;
    align-items: center;
    padding-left: var(--vam-spacing-md-md);
  }

  .m1-counter {
    text-align: center;
    padding: 10px 0 0;
  }
}

@media only screen {
  .m1-left-side-box .m1-navigation-main {
    padding: 0 var(--vam-spacing-md-md) var(--vam-spacing-md-md);
  }

  .m1-menu-icon-items-box {
    background: rgb(var(--vamc-primary-700));
    padding: var(--vam-spacing-md-md);
  }

  .m1-menu-icon-items-list {
    display: flex;
    flex-direction: column;
    gap: var(--vam-spacing-md-sm);
  }

  .m1-left-side-box .m1-navigation-main > li {
    padding: 14px 0;
    border-bottom: 1px dashed rgb(var(--vamc-stroke-contrast-300));
  }

  .m1-left-side-box .m1-navigation-main > li.dropdown-wrapper {
    padding: 0 0 14px 0;
  }

  .m1-menu-icon-items-list > li > a {
    text-decoration: none;
    transition: color 0.3s ease;
    color: rgb(var(--vamc-text-soft-ink));
    font-family: var(--vam-comfort-heading-small-font-name);
    font-size: var(--vam-comfort-heading-small-font-size);
    font-weight: var(--vam-comfort-heading-small-font-weight);
    line-height: var(--vam-normal-heading-small-font-line-height);

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .m1-menu-icon-items-list > li.ico span {
    width: 24px;
    height: 24px;
    display: inline-flex;
    box-sizing: border-box;
  }

  .m1-menu-icon-items-list > li.ico .ico-label {
    display: inline-block;
  }

  .m1-menu-icon-items-list > li.ico span svg {
    fill: none;
    width: 22px;
    height: 24px;
  }

  .m1-left-side-box .m1-navigation-main > :not(.left) {
    display: none;
  }

  /* remove ni feature */
  .m1-left-side-box .m1-navigation-main > li.ico a {
    display: flex;
    align-items: center;
  }

  .m1-left-side-box .m1-navigation-main > li.ico span {
    width: 26px;
    height: 26px;
    display: inline-flex;
    background: var(--main-link-color);
    border-radius: 15px;
    padding: 4px;
    box-sizing: border-box;
    margin-left: 5px;
  }

  .m1-left-side-box .m1-navigation-main > li.ico span svg {
    display: block;
    width: 100%;
    fill: var(--white-color);
    height: 100%;
  }

  .m1-left-side-box .m1-navigation-main > li > .m1-navigation-main__label {
    text-decoration: none;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;

    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-heading-small-font-name);
    font-size: var(--vam-comfort-heading-small-font-size);
    font-weight: var(--vam-comfort-heading-small-font-weight);
    line-height: var(--vam-normal-heading-small-font-line-height);
  }

  .m1-left-side-box .m1-navigation-main > li > a:hover {
    color: rgb(var(--vamc-text-contrast-accent));
    transition: color 0.3s ease;
  }

  .m1-left-side-box .m1-navigation-main > li .dropdown-content {
    padding: var(--vam-spacing-sm-lg) var(--vam-spacing-md-xs);
    display: flex;
    flex-direction: column;
    gap: var(--vam-spacing-md-sm);
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(var(--navigation-animation-offset));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(var(--navigation-animation-offset));
  }
}

@media only screen {

  .m1-editions-switcher-mobile {
    display: block;
    margin: 20px 0 10px 0;
  }

  .m1-side-logo {
    height: 40px;
    width: 210px;
    display: block;
    text-align: center;
  }

  .m1-side-logo svg {
    max-width: 100%;
    position: relative;
    left: -7px;
  }

  body[data-edtn *= 'ev_'] .m1-side-logo svg {
    left: 0;
  }
}

@media only screen {
  .m1-search-form {
    position: relative;
    white-space: nowrap;
    font-size: 0;
    line-height: 0;
    min-width: 0;
  }

  .m1-search-form-text::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  .m1-search-form-text::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  .m1-search-form-text::-webkit-search-decoration,
  .m1-search-form-text::-webkit-search-cancel-button,
  .m1-search-form-text::-webkit-search-results-button,
  .m1-search-form-text::-webkit-search-results-decoration {
    display: none;
  }
}

@media only screen and (max-width: 1279px) {
  .m1-drawer.m1-search-drawer {
    left: 0;
    top: var(--search-drawer-offset);
  }
}

@media only screen and (max-width: 767px){

  .m1-drawer.m1-search-drawer {
    width: 100vw;
  }

  .m1-drawer.m1-search-drawer + .m1-drawer-overlay {
    height: calc(100% - var(--search-drawer-offset));
    top: var(--search-drawer-offset);
  }

  .m1-drawer.m1-search-drawer #header_search_form {
    justify-content: space-between;
  }

  .m1-right-side div.m1-drawer.m1-search-drawer #header_search_form input.m1-search-panel-input {
    width: inherit;
  }

}

@media only screen and (max-width: 600px) {
  .m1-drawer.user-auth-menu,
  .m1-drawer.m1-editions-drawer,
  .m1-drawer.m1-drawer-sidebar {
    width: 100vw;
  }
}