form.auto {
  min-width: none;
  max-width: none;
}

/* .textRtl form label {
  text-align: right;
} */

/* form .label-text.big,
form label.big > span, */

form legend.big {
  font-size: 17px;

  /* font-weight: 600; */
}

form .label-text.light,
form label.light > span,
form legend.light {
  color: #fff;
}

/* input::-webkit-input-placeholder {
  color: #000;
  font-weight: 400;
}

input::-moz-placeholder {
  color: #000;
  font-weight: 400;
  opacity: 1;
}

input:-moz-placeholder {
  color: #000;
  font-weight: 400;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #000;
  font-weight: 400;
}

label.light input::-webkit-input-placeholder {
  color: #fff;
}

label.light input::-moz-placeholder {
  color: #fff;
}

label.light input:-moz-placeholder {
  color: #fff;
}

label.light input:-ms-input-placeholder {
  color: #fff;
}

textarea,
input,
select {
  font-family: var(--main-font-family);
  border-radius: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
} */

/* textarea,
input,
button {
  -webkit-appearance: none;
} */

label [type='radio'] ~ span,
label [type='checkbox'] ~ span {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  line-height: 24px;
}

label.big [type='radio'] ~ span,
label.big [type='checkbox'] ~ span {
  padding-left: 38px;
  line-height: 30px;
}

/* .textRtl label [type='radio'] ~ span,
.textRtl label [type='checkbox'] ~ span {
  padding-left: 0;
  padding-right: 32px;
}

.textRtl label.big [type='radio'] ~ span,
.textRtl label.big [type='checkbox'] ~ span {
  padding-right: 38px;
} */

label [type='radio'],
label [type='checkbox'] {
  display: none;
}

/* .loginFormWrapper #auth_form label {
  margin-top: -28px;
}

.loginFormWrapper #auth_form label [type='checkbox'] {
  display: block;
  width: 150px;
  height: 24px;
  position: relative;
  top: 28px;
  left: -4px;
  z-index: 100;
  opacity: 0;
} */

label [type='radio'] ~ span::before,
label [type='checkbox'] ~ span::before {
  width: 22px;
  height: 22px;
  border: 1px solid rgb(181, 181, 181);
  font-size: 0;
  line-height: 0;
  content: '';
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

label.light [type='radio'] ~ span::before,
label.light [type='checkbox'] ~ span::before {
  background: transparent;
  border-color: #fff;
}

label.big [type='radio'] ~ span::before,
label.big [type='checkbox'] ~ span::before {
  width: 28px;
  height: 28px;
}

label [type='radio'] ~ span::before {
  border-radius: 12px;
}

label.big [type='radio'] ~ span::before {
  border-radius: 15px;
}

/* .textRtl label [type='radio'] ~ span::before,
.textRtl label [type='checkbox'] ~ span::before {
  left: auto;
  right: 0;
} */

label [type='radio'] ~ span::after,
label [type='checkbox'] ~ span::after {
  width: 8px;
  height: 8px;
  font-size: 0;
  line-height: 0;
  content: '';
  background: rgba(25, 152, 252, 0);
  position: absolute;
  top: 8px;
  left: 8px;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

label.light [type='radio'] ~ span::after,
label.light [type='checkbox'] ~ span::after {
  background: rgba(255, 255, 255, 0);
}

label.big [type='radio'] ~ span::after,
label.big [type='checkbox'] ~ span::after {
  width: 10px;
  height: 10px;
  top: 10px;
  left: 10px;
}

label [type='radio'] ~ span::after {
  border-radius: 4px;
}

label.big [type='radio'] ~ span::after {
  border-radius: 5px;
}

/* .textRtl label [type='radio'] ~ span::after,
.textRtl label [type='checkbox'] ~ span::after {
  left: auto;
  right: 8px;
}

.textRtl label.big [type='radio'] ~ span::after,
.textRtl label.big [type='checkbox'] ~ span::after {
  right: 10px;
} */

label [type='radio']:checked ~ span::after,
label [type='checkbox']:checked ~ span::after {
  background: rgba(25, 152, 252, 1);
}

label.light [type='radio']:checked ~ span::after,
label.light [type='checkbox']:checked ~ span::after {
  background: rgba(255, 255, 255, 1);
}

/* input:not([type='submit']):not([type='button']):not([type='reset']):not([type='checkbox']):not([type='radio']),
textarea {
  width: 100%;
  padding: 0 5px;
  line-height: 28px;
  color: #000;
} */

label.big input:not([type='submit']):not([type='button']):not([type='reset']):not([type='checkbox']):not([type='radio']) {
  line-height: 38px;
  padding: 0 10px;
}

label.light input:not([type='submit']):not([type='button']):not([type='reset']):not([type='checkbox']):not([type='radio']) {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

/* input:not([type='submit']):not([type='button']):not([type='reset']):not([type='checkbox']):not([type='radio']),
textarea,
select {
  font-size: 17px;
  border: 1px solid rgb(181, 181, 181);
  background: #fff;
  margin-bottom: 15px;
} */

select {
  background: var(--select-field-menu-bg);
  padding: 1px 5px;

  /* -webkit-appearance: none; */
}

label.big select {
  padding: 6px 8px;
}

label.light select {
  background: rgba(0, 0, 0, 0.01);
  color: #fff;
  border-color: #fff;
}

textarea:focus,
select:focus {
  border-color: var(--main-link-color) !important;

  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.2); */
  outline: none;
}

label input:not([type='submit']):not([type='button']):not([type='reset']):not([type='checkbox']):not([type='radio']),
label textarea,
label select {
  margin-bottom: 0;
}

form div.error {
  color: #f00;
  font-size: 13px;
  margin: -10px 0 7px;
}

input.error,
textarea.error,
select.error {
  border-color: #f00 !important;
}

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
  border: none;
  padding: 0;
}

/* input[type='submit'],
input[type='button'],
button,
.aButton,
.button {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 28px;

  
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 25px;
  font-weight: normal;
  outline: none;
  cursor: pointer;
  color: #010101;
  background: transparent;
  border: 1px solid #000;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
} */

/* .textRtl input[type='submit'],
.textRtl input[type='button'],
.textRtl button,
.textRtl .aButton,
.textRtl .button {
  margin-right: 0;
  margin-left: 10px;
} */

/* .aButton,
input[type='submit'],
button[type='submit'] {
  background: var(--main-link-color);
  border: 1px solid var(--main-link-color);
  color: #fff;
  font-weight: 600;
} */

input[type='button']:active,
button:active:not([type='submit']):not(.wait),
.button:active {
  border-color: #000;
}

.aButton:active:not(.wait),
input[type='submit']:active:not(.wait),
button[type='submit']:active:not(.wait) {
  border-color: #000;
}

input[type='submit']:focus,
input[type='button']:focus,
button:focus,
.aButton:focus,
.button:focus {
  border-color: #323232;
  outline: none;
}

.aButton:hover,
input[type='submit']:hover,
button[type='submit']:hover {
  border-color: #000;
  outline: none;
}

/* input[type='button']:hover,
button:hover:not([type='submit'], .dropdown-toggle, .ss-button),
.button:hover {
  background: #000;
  color: #fff;
  outline: none;
  text-decoration: none;
} */

.aButton.disabled,
.button.disabled,
input[type='button'].disabled,
input[type='submit'].disabled,
input[type='button'][disabled],
input:not(.select2-container)[type='submit'][disabled],
button.disabled,
button[disabled] {
  color: #fff !important;
  background: #dadada !important;
  border: 1px solid #b0b0b0 !important;
  cursor: default;
}

.big input[type='submit'],
.big input[type='button'],
.big button,
.big .aButton,
.big .button {
  line-height: 38px;
}

.light [type='submit'],
.light [type='submit']:hover,
.light [type='submit']:focus {
  background: rgba(255, 0, 0, 0.9);
  border: none !important;
  line-height: 40px;
}

.light .go[type='submit'] {
  position: relative;
  border-radius: 3px 0 0 3px;
  font-size: 15px;
  padding: 0 15px;
}

.light .go[type='submit']::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border-style: solid;
  border-color: transparent transparent transparent rgba(255, 0, 0, 0.9);
  border-width: 20px 0 20px 15px;
  left: 100%;
}

.light [type='submit'][disabled],
.light [type='submit'].disabled {
  /* background: rgba(0,0,0,0.5) !important;
    border-color: rgba(0,0,0, 0.05) !important; */
  background: rgba(255, 0, 0, 0.85) !important;
}

.light .go[type='submit'][disabled]::after,
.light .go[type='submit'].disabled::after {
  border-left-color: rgba(255, 0, 0, 0.85);
}

.aButton.wait,
input[type='submit'].wait,
input[type='button'].wait,
button.wait,
.button.wait {
  background-color: transparent !important;
  border-color: var(--main-text-color-special) !important;
  color: var(--main-text-color-special) !important;
  background-image:
 -webkit-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-image:
 -moz-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-image:
 -ms-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-image:
 linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.5) 75%,
    transparent 75%,
    transparent
  );
}

.preloaderBox,
.photoDetail .photoBox .photoControls .btn.wait::before,
input[type='button'].wait,
input[type='submit'].wait,
button.wait,
.aButton.wait,
.button.wait {
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -ms-background-size: 50px 50px;
  background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -ms-animation: move 2s linear infinite;
  animation: move 2s linear infinite;
}

.buttons {
  font-size: 0;
  line-height: 0;
  text-align: left;
}

/* .textRtl .buttons {
  text-align: right;
} */

.buttons * {
  margin-bottom: 0 !important;
}

.buttons.right {
  text-align: right;
}

/* .textRtl .buttons.right {
  text-align: left;
} */

.buttons.right :last-child {
  margin-right: 0;
}

/* .textRtl .buttons.right :last-child {
  margin-left: 0;
} */

.buttons.center {
  text-align: center;
}

.buttons.pb {
  margin-bottom: 25px;
}

/* .searchButton {
  background: none !important;
  padding: 0;
  border: none !important;
  width: 30px;
  height: 30px;
  color: #000 !important;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  margin: 0;
} */

.searchButton::before {
  font-family: 'icons';
  content: '\e802';
}

/* .inPageSearchForm {
  display: table;
  width: 100%;
  margin: 25px 0;
} */

/* .inPageSearchForm > label {
  display: table-cell;
  vertical-align: bottom;
  margin: 0;
}

.inPageSearchForm > label + label {
  width: 1%;
} */

#captcha-image {
  vertical-align: middle;
  width: 50%;
  height: auto;
  margin-right: 5%;
  margin-bottom: 15px;
}

#captcha {
  width: 40%;
  vertical-align: middle;
}

.button .active {
  display: none;
}

.button.active .passive {
  display: none;
}

.button.active .active {
  display: inline-block;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@-ms-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

/* .textRtl .captcha-image-box {
  float: left;
  margin-left: 0;
  margin-right: 25px;
} */
