@media only screen {
  .share-box-icon-list .share-button {
    display: none;
  }

  .share-box-icon-list .share-list li .count,
  .share-box-icon-list .share-list li .name {
    display: none;
  }

  .share-box-icon-list .share-list {
    font-size: 0;
    line-height: 0;
  }

  .share-box-icon-list .share-list li {
    display: inline-block;
  }

  /* .textRtl .share-box-icon-list .share-list li {
    margin-left: 5px;
    margin-right: 0;
  } */

  .share-box-icon-list .share-list li a {
    text-decoration: none;
  }

  .share-box-icon-list .share-list li .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    overflow: hidden;
    text-align: center;
    display: block;
  }

  .share-box-icon-list .share-list li {
    display: block;
  }

  /* .share-box-dropdown {
    font-size: 0;
    line-height: 0;
  } */

  .share-box-dropdown .share-box-wrapper {
    position: relative;
    display: inline-block;
  }

  .share-box-dropdown .share-box-wrapper:hover {
    z-index: 999;
  }

  .share-box-dropdown .share-button {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;

    /* border:1px var(--share-box-color-bg) solid; */
    border: 1px transparent solid;

    /* color: var(--share-box-color-bg) !important; */
    color: var(--main-text-color-special);
    line-height: 20px !important;
    height: 20px;
    font-size: 13px !important;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 10;
    border-radius: 3px;
  }

  .touch .share-box-dropdown .share-button {
    pointer-events: none;
  }

  .share-box-dropdown.light .share-button {
    border-color: var(--white-color);
    color: var(--white-color) !important;
  }

  .share-box-dropdown .share-box-wrapper:hover .share-button {
    color: var(--white-color) !important;
    background: var(--share-box-color-bg);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .share-box-dropdown.light .share-box-wrapper:hover .share-button {
    border-color:
 var(--share-box-color-bg) var(--white-color)
      var(--white-color);
  }

  .share-box-dropdown .share-box-wrapper:hover .share-button .social-share-icon {
    fill: var(--white-color);
  }

  .share-box-dropdown .share-button .total,
  .share-box-dropdown .share-button .name {
    display: inline-block;

    /* display: none; */
    margin-right: 8px;
    max-width: 55px;

    /* min-width: 20px; */
    overflow: hidden;
    text-align: center;
  }

  .share-box-dropdown .share-button .total {
    font-size: 14px;
    font-weight: 600;
  }

  .share-box-dropdown .share-button .name {
    display: none;
  }

  /* .textRtl .share-box-dropdown .share-button .total,
  .textRtl .share-box-dropdown .share-button .name {
    margin-right: 0;
    margin-left: 7px;
  } */

  .share-box-dropdown .share-button .icon {
    margin-top: 0;
    display: inline-block;
    vertical-align: top;
  }

  .share-box-dropdown .share-button .share-empty::before {
    font-size: 16px;
    font-weight: 600;
  }

  /* .textRtl .share-box-dropdown .share-button .icon {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  } */

  /* .share-box-dropdown .share-list {
    z-index: 5;
    overflow: hidden;
    position: absolute;
    bottom: 21px;
    left: 0;
    max-height: 0;
    -webkit-transition: max-height 0.1s ease;
    transition: max-height 0.1s ease;
  } */

  .share-box-dropdown.share-box-align-right .share-list {
    left: auto;
    right: 0;
  }

  .share-box-dropdown.to-down .share-list {
    bottom: auto;
    top: 21px;
  }

  .content-area .share-box-dropdown.to-down .share-list {
    bottom: 21px;
    top: auto;
  }

  /* .textRtl .share-box-dropdown .share-list {
    left: auto;
    right: 0;
  } */

  /* .textRtl .share-box-dropdown.share-box-align-right .share-list {
    left: 0;
    right: auto;
  } */

  .share-box-dropdown .share-box-wrapper:hover .share-list {
    max-height: 350px;
    -webkit-transition: max-height 0.2s ease;
    transition: max-height 0.2s ease;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .share-box-dropdown .share-list ul {
    padding: 5px 0;
    /* background: rgba(var(--white), 0.9); */
    border: 1px solid;
    border-radius: 3px;
  }

  .root:not(#page_index_articles_detail) .share-box-dropdown .share-list ul,
  .lg-additional-share .share-box-dropdown .share-list ul {
    background: var(--dropdown-color-social-bg);
  }

  .share-box-dropdown.light .share-list ul {
    background: rgba(var(--black), 0.75);
    border-color: var(--white-color);
  }

  .share-box-dropdown .share-list li > a {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: var(--main-text-color-special) !important;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
  }

  #page_index_photos_gallery-detail .share-box-dropdown .share-list li > a {
    padding: 7px 20px;
  }

  .share-box-dropdown.light .share-list li > a {
    color: var(--white-color) !important;
  }

  .share-box-dropdown .share-list li > a:hover {
    background: var(--register-form-wrapper-footer);
  }

  .share-box-dropdown.light .share-list li > a:hover {
    background: var(--share-box-color-bg);
  }

  .share-box-dropdown .share-list li > a {
    text-decoration: none;
  }

  .share-box-dropdown .share-list span.social-bg {
    display: inline-flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    order: 0 !important;
    /* line-height: 25px; */
  }

  .share-box-dropdown .share-list .social-bg svg {
    width: 14px;
    height: 14px;
  }

  .share-box-dropdown .share-list span.name {
    min-width: 125px;
    display: inline-block;
    margin: 0 10px;
    order: 1;
  }

  .share-box-dropdown .share-list .count {
    background: var(--register-form-wrapper-footer);
    padding: 2px 5px;
    display: inline-block;
  }

  .share-box-dropdown.light .share-list .count {
    background: var(--black-color);
  }

  /* .parent-shareBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 0;
    line-height: 0;
    margin: 25px auto 10px;
  } */

  .big-shareBox.share-box-dropdown .share-box-wrapper {
    display: flex;
    position: relative;
    justify-content: flex-end;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator.active + .share-button {
    display: none;
  }

  .big-shareBox.share-box-dropdown .share-button,
  .big-shareBox.share-box-dropdown .share-button:hover {
    display: flex;
    height: 40px;
    align-items: center;
    pointer-events: none;
  }

  .big-shareBox.share-box-dropdown .share-button,
  .big-shareBox.share-box-dropdown .share-button:hover,
  .big-shareBox.share-box-dropdown .share-box-wrapper:hover .share-button {
    background: transparent !important;
    color: var(--black-color) !important;
    padding: 0;
  }

  .big-shareBox.share-box-dropdown .share-list {
    /* width: 144px; */
    max-width: 142px;
    margin-top: -4px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    overflow: hidden;
    max-height: 42px;
    height: 42px;
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    display: flex;
    min-height: 46px;
    transition: max-width 0.35s ease-out;
    transform: translate3d(0, 0, 0);
  }

  .big-shareBox.share-box-dropdown .share-list.enabled {
    transition: max-width 0.35s ease-out;
    max-width: 500px;
    width: auto;
  }

  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list ul {
    display: flex;
    padding: 0;
    margin: 0;
    border: 0;
    padding: 4px 0 0 4px;
    box-sizing: border-box;
  }

  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list li {
    margin-right: 4px;
    margin-bottom: 4px;
    order: 5;
    display: flex;
  }

  body[data-edtn] .shareBox .share-box-wrapper ul .facebook {
    order: 0;
  }

  body[data-edtn] .shareBox .share-box-wrapper ul .whatsapp {
    order: 2;
  }

  body[data-edtn] .shareBox .share-box-wrapper ul .twitter {
    order: 1;
  }

  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='me'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .twitter,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .twitter, 
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .twitter {
    order: 2;
  }
  
  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .whatsapp, 
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='me'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .whatsapp {
    order: 1;
  }

  body[data-edtn='us'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .whatsapp,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .whatsapp {
    order: 2;
  }

  body[data-edtn='us'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='lat'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .flipboard, 
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='me'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .flipboard {
    order: 3;
  }

  body[data-edtn='us'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .mailto {
    order: 4;
  }
  
  body[data-edtn='us'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .mailto,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .flipboard,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .linkedin {
    order: 5;
  }

  .shareBox .share-box-wrapper ul .reddit {
    order: 6;
  }

  body[data-edtn='us'] .shareBox .share-box-wrapper ul .pinterest,
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_br'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .linkedin {
    order: 6;
  }

  @media screen and (max-width: 767px) {
    body[data-edtn='hu'] .shareBox .share-box-wrapper ul .flipboard,
    body[data-edtn='id'] .shareBox .share-box-wrapper ul .flipboard,
    body[data-edtn='ar'] .shareBox .share-box-wrapper ul .flipboard,
    body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .flipboard,
    body[data-edtn='me'] .shareBox .share-box-wrapper ul .flipboard,
    body[data-edtn='es'] .shareBox .share-box-wrapper ul .flipboard {
      order: 4;
    }

    body[data-edtn='hu'] .shareBox .share-box-wrapper ul .linkedin,
    body[data-edtn='id'] .shareBox .share-box-wrapper ul .linkedin,
    body[data-edtn='ar'] .shareBox .share-box-wrapper ul .linkedin,
    body[data-edtn='ev_ar'] .shareBox .share-box-wrapper ul .linkedin,
    body[data-edtn='me'] .shareBox .share-box-wrapper ul .linkedin,
    body[data-edtn='es'] .shareBox .share-box-wrapper ul .linkedin {
      order: 5;
    }

    body[data-edtn='au'] .shareBox .share-box-wrapper ul .pinterest,
    body[data-edtn='ev_au'] .shareBox .share-box-wrapper ul .pinterest {
      display: none;
    }
  }
  
  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list li > a,
  .big-shareBox.share-box-dropdown .share-list span {
    padding: 0;
    line-height: 0;
    height: 42px;
    width: 42px;
  }

  .big-shareBox.share-box-dropdown .share-list span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .big-shareBox.share-box-dropdown .share-list span svg {
    width: 24px;
    height: 24px;
  }

  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list .name,
  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list .count,
  .big-shareBox.share-box-dropdown .share-button .total,
  .big-shareBox.share-box-dropdown .name,
  .shareBox-separator {
    display: none;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator {
    padding: 0;
    height: 42px;
    width: 42px;
    min-width: 42px;
    background: #aaa;
    color: #fff;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: default;
    opacity: 1;
    transition: opacity 0.15s ease-out;
    order: 3;
  }

  .shareBox-separator .active,
  .shareBox-separator .passive {
    padding: 0;
    height: 42px;
    width: 42px;
    min-width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shareBox-separator .active:hover,
  .shareBox-separator .passive:hover {
    cursor: pointer;
  }

  .shareBox-separator.active .active {
    display: flex;
  }

  .shareBox-separator .active {
    display: none;
  }

  .shareBox-separator.active .passive {
    display: none;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator .icon-plus {
    transition: transform 0.15s ease-out, opacity 0.15s ease-out;
    transform: rotate(0);
    font-family: var(--main-font-family);
    font-size: 32px;
    line-height: 0;
    font-weight: bold;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator:not(.active):hover .icon-plus {
    transform: rotate(90deg);
  }

  .big-shareBox.share-box-dropdown .shareBox-separator.active .icon-plus {
    transform: rotate(45deg);
  }

  /* .postBody + .parent-shareBox {
    margin-top: 30px;
  } */

  .icon.arrow-prev::before {
    content: '\e81d';
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: inline-block;
  }

  .icon.arrow-next::before {
    content: '\e81d';
  }

  .icon.arrow-down::before {
    content: '\e81d';
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-block;
    width: 100%;
  }

  .icon.arrow-big-next::before {
    content: '\e81b';
  }

  .icon.arrow-big-prev::before {
    content: '\e81b';
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: inline-block;
  }

  .icon.settings::before {
    content: '\e82b';
  }
}

@media only screen and (max-width: 767px) {

  .big-shareBox.share-box-dropdown .share-button {
    height: 31px;
  }

  .big-shareBox.share-box-dropdown .share-list {
    max-width: 112px;
    margin-top: -4px;
    max-height: 35px;
    height: 38px;
    min-height: 38px;
  }

  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list li > a,
  .big-shareBox.share-box-dropdown .share-list span {
    /* font-size: 16px; */
    height: 32px;
    width: 32px;
  }

  .big-shareBox.share-box-dropdown .share-list span svg {
    width: 16px;
    height: 16px;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator {
    height: 32px;
    width: 32px;
    min-width: 32px;
  }

  .shareBox-separator .active,
  .shareBox-separator .passive {
    height: 31px;
    width: 32px;
    min-width: 32px;
  }

  .share-list ul {
    display: flex;
    justify-content: center;
  }

  .postMiddleInfo .share-list ul,
  .additional-item.lg-additional-share .share-list ul {
    flex-wrap: wrap;
    flex-direction: column;
  }

  body[data-edtn='us'] .shareBox .share-box-wrapper ul .pinterest,
  body[data-edtn='fr'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='it'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='de'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_de'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='br'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='tr'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_tr'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='es'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ar'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='hu'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='id'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ra_us'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_us'] .shareBox .share-box-wrapper ul .linkedin,
  body[data-edtn='ev_fr'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ev_it'] .shareBox .share-box-wrapper ul .reddit,
  body[data-edtn='ot'] .shareBox .share-box-wrapper ul .reddit {
    display: none;
  }

  /* body[data-edtn='ev_us'] .share-list ul li.facebook {
    order: 0;
  }

  body[data-edtn='ev_us'] .share-list ul li.twitter {
    order: 1;
  }

  body[data-edtn='ev_us'] .share-list ul li.whatsapp {
    order: 2;
  }

  body[data-edtn='ev_us'] .share-list ul li.flipboard {
    order: 3;
  }

  body[data-edtn='ev_us'] .share-list ul li.reddit {
    order: 4;
  } */

  /* .parent-shareBox-bottom {
    justify-content: flex-start;
    padding: 10px 0 0;
    margin: 0 auto;
  } */

  .big-shareBox.share-box-dropdown .share-box-wrapper {
    margin-left: -4px;
  }
}

@media only screen and (max-width: 374px) {
  .big-shareBox.share-box-dropdown .share-button {
    height: 25px;
  }

  .big-shareBox.share-box-dropdown .share-list {
    max-width: 103px;
    margin-top: -4px;
    max-height: 29px;
    height: 33px;
    min-height: 33px;
  }

  .big-shareBox.share-box-dropdown .share-box-wrapper .share-list li > a,
  .big-shareBox.share-box-dropdown .share-list .icon {
    font-size: 16px;
    height: 29px;
    width: 29px;
  }

  .big-shareBox.share-box-dropdown .shareBox-separator {
    height: 29px;
    width: 29px;
    min-width: 29px;
  }

  .shareBox-separator .active,
  .shareBox-separator .passive {
    height: 29px;
    width: 29px;
    min-width: 29px;
  }
}

@media (min-width: 768px) {
  .shareBox .whatsapp {
    display: none !important;
  }

  .postAuthorBox .shareBox {
    display: block !important;
    margin-left: auto;
  }

  /* .parent-shareBox-bottom {
    justify-content: center;
  } */

  /* .parent-shareBox-bottom .shareBox {
    display: none !important;
  } */
}

@media screen and (max-width: 768px) {
  /* .parent-shareBox-bottom  div {
    width: 100%;
  } */

  /* .parent-shareBox-bottom .shareBox {
    display: block !important;
  } */

  .postAuthorBox .shareBox {
    display: none !important;
  }

  /* .parent-shareBox-bottom .shareBox .share-box-wrapper {
    justify-content: center;
  } */
}


/* -------- top sharebar styles ----------- */

@media screen and (min-width: 768px) {

  .postTopInfo .shareTrendingBox .shareBox {
    min-height: 50px;
    min-width: 190px;
  }

  .postTopInfo .shareTrendingBox .shareBox .share-box-wrapper {
    position: absolute;
  }

  .postMeta-Top .share-box-dropdown .share-box-wrapper .share-list {
    min-height: 48px;
    transition: min-height 0.35s ease-out;
    max-width: 188px;
  }
  
  .postMeta-Top .big-shareBox.share-box-dropdown .share-list.enabled {
    margin-left: 2px;
    flex-wrap: wrap;
    min-height: 96px;
    transition: min-height 0.35s ease-out;
  }
  
  .postMeta-Top .big-shareBox.share-box-dropdown .share-box-wrapper .share-list ul {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
  }
}
