.cmpTrayAlert{
    position:fixed;
    left:50%;
    margin-left: -150px;
    top:10px;
    width: 300px;
    z-index: 999;
    overflow:hidden;
    max-height:90%;
    max-height:calc(100% - 20px);
}
@media only screen and (min-width : 768px) {
    .cmpTrayAlert{
        max-width: 500px;
    }
}
.cmpTrayAlert:hover{
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.cmpTrayAlert > .item{
    background:rgba(25, 152, 252, 0.9);
    color:#fff;
    position:relative;
    margin-bottom:1px;
    overflow:hidden;
    max-height:0; 
    top:-500px;
    opacity: 0;
}
.cmpTrayAlert > .visible{
    opacity: 1;
    top:0;
    max-height:500px;
    z-index:3;
    -webkit-transition:max-height 0.8s, top 0.5s, opacity 0.7s;
            transition:max-height 0.8s, top 0.5s, opacity 0.7s;
}
.cmpTrayAlert > .deleted{
    top: -500px;
    opacity: 0;
    z-index:2;
    -webkit-transition: top 0.5s, opacity 0.3s;
            transition: top 0.5s, opacity 0.3s;
}
.cmpTrayAlert > .error{
    background: rgba(255,0,0, 0.9);
    color:#fff;
}
.cmpTrayAlert .text{
    padding:10px 35px 10px 10px;
    font-size: 14px;
}
    .cmpTrayAlert .text ul li{
        padding:3px 0;
        list-style-type: circle; 
        margin-left:15px;
    }

.cmpTrayAlert .close{
    position:absolute;
    top:5px;
    right:5px;
    color:#fff;
    cursor:pointer;
}
.cmpTrayAlert > .error .close{
    color:#fff;
}
.cmpTrayAlert .close:hover{
    opacity: 0.75;
}
.cmpTrayAlert .close:before{
    font-size: 12px;
}