@media only screen {
  .m1-drawer li.dropdown {
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
  }

  .m1-drawer li.dropdown.active {
    border-bottom: 1px solid transparent;
  }

  .m1-drawer li.dropdown a {
    padding-right: 24px;
  }

  .m1-drawer .dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
    padding-right: 0;
  }

  .dropdown .dropdown-toggle svg {
    width: 24px;
    height: 24px;
    transition: 0.3s transform, 0.3s color;
    stroke: rgb(var(--vamc-text-contrast-accent));
  }

  .dropdown .active svg {
    transform: rotateZ(180deg);
  }


  .dropdown-wrapper .dropdown-content li a {
    display: block;
    width: 100%;
    transition: color 0.3s ease;

    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-title-medium-font-name);
    font-size: var(--vam-comfort-title-medium-font-size);
    font-style: normal;
    font-weight: var(--vam-comfort-title-medium-font-weight);
    line-height: var(--vam-normal-title-medium-font-line-height);
  }

  .dropdown-wrapper .dropdown-content li a:hover {
    color: rgb(var(--vamc-text-contrast-accent));
    text-decoration: none !important;
  }

  .inner-scroll ~ .dropdown-wrapper .dropdown-content {
    max-height: 280px;
    overflow-x: auto;
  }

  .inner-scroll ~ .dropdown-wrapper .dropdown-content::-webkit-scrollbar {
      width: 2px;
  }

  .inner-scroll ~ .dropdown-wrapper .dropdown-content::-webkit-scrollbar-thumb {
    background-color: rgba(77, 73, 73, 0.5);
    border-radius: 2px;
  }
}