.cmpDialog{
    overflow:hidden;
    z-index:999;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;

    /*-webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;*/
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display:none;
}
.cmpDialog.visible{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.cmpDialog .overlay{
    z-index:-1;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.5);
    overflow:hidden;
}
.cmpDialog .box{
    min-width:300px;
    max-width:400px;
    position:relative;
    background: var(--white-bg-color);
    z-index:2;
}
.cmpDialog.error .box{
    background:#be2a4e;
}
.cmpDialog.error .overlay{
    background:rgba(71,9,22,0.5);
}
.cmpDialog .box .title{
    padding: 10px 35px 10px 10px;
    background: rgb(25, 152, 252);
    color:#fff;
}
.cmpDialog .box .content{
    padding:15px;
}
.cmpDialog .box .close{
    float:right;
    margin: 5px 5px 0 0;
    font-size: 12px;
    color: var(--main-text-color);
    cursor: pointer;
}
.cmpDialog .box .close:hover{
    opacity: 0.75;
}
.cmpDialog .box button.focusOn{
    border-color:red;
}
.cmpDialog .box .buttons{
    padding:0 15px 15px;
    text-align:center;
}
.cmpDialog .box .buttons .cancel{
    margin-left:10px;
}
.cmpDialog .box .buttons .cancel:before{
    content: attr(title);
}
.cmpDialog .box .buttons .ok:before{
    content: attr(title);
}

