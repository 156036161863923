@media print {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    color: #262626;
    font-family: var(--main-font-family);
    font-size: 18pt;
    line-height: 1.3;
    -webkit-text-size-adjust: 100%;
  }

  p {
    margin-bottom: 10pt;
  }

  h1,
  h2,
  h3 {
    margin: 20pt 0;
  }

  h1 {
    font-size: 24pt;
  }

  h2,
  h3 {
    font-size: 20pt;
  }

  img {
    max-width: 100%;
  }

  a,
  a:hover {
    color: #262626;
    text-decoration: none;
  }

  /* hidden blocks */
  template,
  .apb .ap,
  .alarm,
  .cmpDialog,
  .cmpDialog + iframe,
  .cmpTrayAlert,
  .hiddenBlock,
  .apOverlay,
  .pageAdSkin,
  .m1-header-ad,
  .m1-header-main .box-item-left,
  .m1-header-main .box-item-right,
  .m1-header-main-bottom-stick,
  .m1-left-side,
  .m1-right-side,
  .breadcrumbs-box,
  .shareTrendingBox,
  .m1-find-vehicle-mobile,
  .requestsProfilerBrief,
  .sidebar,
  .apInarticleSmallRes,
  .trending-content,
  .requestsProfilerDetail,
  .entityInfoBlock,
  .car-specs-box,
  .buttons.pb,
  .relatedContent-new,
  .print-hide,
  #floating_share_box,
  #ap_popup_gallery_top,
  #ap_popup_gallery_rectangle,
  #share_box_content_wrapper,
  #layout_header_spacer,
  .m1-article-slideshow-photos-bottom-ad,
  .m1-ap-native,
  .mobile-skyscraper-box,
  .m1-footer-messages,
  #catfish {
    display: none !important;
  }

  .m1-header-main {
    text-align: center;
    height: 100%;
  }

  .m1-header-logo {
    -webkit-print-color-adjust: exact !important;
    background-image: url(../../../img/logo_black.png);
    background-size: 100%;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    display: block;
    position: relative;
    margin: 0 auto;
  }

  /* body[data-edtn='it'] .m1-header-logo {
    background-image: url(../../../img/logo_x2-m1oa-black.png);
    background-size: 75%;
  } */

  .articleHeader {
    position: relative;
  }

  .articleHeader img {
    width: 100%;
  }

  .articlePoster img {
    display: none;
  }

  .articleTitle h1 {
    -webkit-print-color-adjust: exact !important;
    color: rgb(245, 245, 245);
    font-size: 25pt;
    padding-left: 20pt;
    position: absolute;
    bottom: 0;
    left: 0;
    text-shadow: 2pt 2pt 2pt rgba(0, 0, 0, 0.2);
  }

  .postAuthorBox {
    display: table;
    margin-bottom: 25pt;
  }

  .postAuthorThumb,
  .postAuthorInfo {
    display: table-cell;
    vertical-align: middle;
    padding-right: 20pt;
  }

  .postAuthor label {
    text-transform: uppercase;
  }

  .postAuthor .author {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
  }

  .userRThumb {
    border: 1pt solid #000;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    width: 80pt;
    height: 80pt;
  }

  .userRThumb img {
    width: 100%;
  }

  .postDate {
    font-size: 14pt;
    padding: 20pt 0;
  }

  .postBody {
    page-break-after: auto;
  }

  .postContent p,
  .content-wrapper p {
    margin-bottom: 10pt;
  }

  .postContent h3 {
    color: var(--heading-color);
    margin: 15pt 0 10pt;
  }

  section[data-widget='mosaic'][data-type='2'] {
    display: table;
    margin: 20pt 0;
    width: 100%;
  }

  section[data-widget='mosaic'][data-type='2'] > [data-widget] {
    display: table-cell;
    width: 50%;
    vertical-align: top;
    padding: 3pt;
  }

  section[data-widget='mosaic'][data-type='3'] > [data-widget] {
    display: table-cell;
    width: 33.33%;
    vertical-align: top;
    padding: 3pt;
  }

  section[data-widget='mosaic'] img {
    max-width: 100%;
  }

  section[data-widget='image'] {
    margin: 30pt 0;
  }

  section[data-widget='embed'] {
    display: none;
  }

  section[data-widget='video'] {
    text-align: center;
    display: none;
  }

  .videoBoxArticle {
    display: none;
  }

  .msnt-photo-thumb-gallery {
    page-break-after: auto;
  }

  .msnt-photo-thumb-gallery-photos-list {
    overflow: hidden;
    height: 95pt;
    margin-bottom: 20pt;
  }

  .msnt-photo-thumb-gallery-photos-list img {
    height: 100%;
  }

  .msnt-photo-thumb-gallery-main-photo {
    position: relative;
  }

  .msnt-photo-thumb-gallery-main-photo-box img {
    max-width: 100%;
  }

  .msnt-photo-thumb-gallery-count {
    -webkit-print-color-adjust: exact !important;
    background: rgba(0, 0, 0, 0.2);
    padding: 5pt 7pt;
    border-radius: 5pt;
    line-height: 24pt;
    position: absolute;
    bottom: 15pt;
    left: 15pt;
  }

  .msnt-photo-thumb-gallery-count .value {
    -webkit-print-color-adjust: exact !important;
    background: rgb(245, 245, 245);
    border-radius: 12pt;
    box-sizing: border-box;
    color: #000;
    display: inline-block;
    font-size: 14pt;
    padding: 0 4pt;
    min-width: 24pt;
    height: 24pt;
    vertical-align: middle;
    text-align: center;
  }

  .msnt-photo-thumb-gallery-count .label {
    color: rgb(245, 245, 245);
    font-size: 14pt;
  }

  .postContent ol,
  .postContent ul {
    list-style: circle outside none;
    margin: 30pt 0 30pt 30pt;
  }

  .content-wrapper ul li {
    list-style: inherit;
    margin-bottom: 10pt;
  }

  .trending-content_header {
    padding-left: 25pt;
    padding-top: 0 !important;
    position: relative;
  }

  .trending-content_item-mobile {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(50% - 10pt);
    width: -moz-calc(50% - 10pt);
    width: calc(50% - 10pt);
  }

  .wrapper-related-item {
    overflow: hidden;
    margin: 20pt 0;
    width: 100%;
  }

  .related-item {
    display: inline-block;
    width: 49%;
    padding: 15pt 15pt 15pt 0;
  }

  .related-item a {
    -webkit-print-color-adjust: exact !important;
    background: var(--main-bg-color);
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .related-item img {
    height: 80pt;
    width: 505;
    margin: 2pt;
    overflow: hidden;
    object-fit: cover;
    vertical-align: middle;
  }

  .title-related-item {
    display: inline-block;
    font-size: 13pt;
    max-height: 80pt;
    line-height: normal;
    overflow: hidden;
    padding-left: 7pt;
    vertical-align: middle;
    width: calc(50% - 7pt);
  }

  .table-wrapper {
    margin: 30pt 0;
  }

  .table-wrapper table {
    width: 100%;
  }

  .table-wrapper table tr:nth-child(odd) {
    -webkit-print-color-adjust: exact !important;
    background: #f5f5f5;
  }

  .table-wrapper table tr:nth-child(even) {
    -webkit-print-color-adjust: exact !important;
    background: #ededed;
  }

  .table-wrapper table td {
    padding: 5pt;
    vertical-align: top;
  }

  .m1-article-slideshow-prev,
  .m1-article-slideshow-next {
    display: none;
  }

  .autonews-table-title {
    margin-bottom: 5pt;
  }

  .autonews-table-title {
    page-break-before: always;
  }

  /* .autonews-rating {
    margin: 0;
    padding: 0;
  }

  .autonews-rating ul {
    margin: 0;
    padding: 0;
    display: block;
  }

  .autonews-rating li {
    list-style-type: none;
    display: block;
  }

  .autonews-rating .table-wrapper {
    margin: 0;
  }

  .autonews-rating td {
    padding: 3pt 0;
    vertical-align: middle !important;
    line-height: 1;
  }

  .autonews-rating tr {
    -webkit-print-color-adjust: exact !important;
    background-color: rgba(255, 255, 255, 0) !important;
    margin: 0;
  }

  .autonews-rating-headline {
    -webkit-print-color-adjust: exact !important;
    background-color: #1998fc;
    color: #fff;
    display: block;
    font-size: 16pt;
    padding: 3pt;
    overflow: hidden;
    text-transform: uppercase;
  }

  .autonews-rating-total {
    float: right;
  }

  .autonews-rating-item {
    list-style-type: none;
    page-break-before: auto;
  }

  .autonews-rating-item-detail {
    font-size: 12pt;
    font-weight: bold;
  }

  .autonews-rating-item-progress table {
    border: 1pt solid #ededed;
  }

  .autonews-rating-item-progress table tr,
  .autonews-rating-item-progress table td {
    padding: 0;
    margin: 0;
  }

  .autonews-rating-item-bar-full {
    -webkit-print-color-adjust: exact !important;
    background-color: #1998fc;
    color: #fff;
    font-size: 10pt;
    padding: 0 5pt;
    text-align: right;
  }

  .autonews-rating-item-comment {
    font-size: 10pt;
  } */
}
