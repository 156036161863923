/* @import './reset.css'; */
@import './secondery-components/btn.css';
@import './components/preloader.css';
/* @import './components/icons.css'; */
/* @import './components/floating_share_box.css'; */
@import './components/share-box.css';
/* @import './components/socials.css'; */
@import './components/jquery-select2.css';
@import './components/forms.css';
@import './components/drawer.css';
/* @import './components/m1-cse-item.css'; */
@import './components/cmp/cmp-tray-alert.css';
@import './components/cmp/cmp-dialog.css';
@import './components/footer-messages.css';
@import './components/print.css';
/* @import './secondery-components/redef-layout.css'; */
/* @import './components/pager-pages.css'; */
@import './components/dropdown.css';
/* @import './components/m1-cbs-widget.css'; */
@import './components/shop/m1-find-vehicle-eu.css';
@import './components/shop/m1-find-vehicle-us.css';
/* @import './components/dp-footer.css'; */

/*@import './components/popup.css';*/


html {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

._side-hidden,
._side-hidden body {
  overflow: hidden;
  height: inherit;
}

._side-hidden {
  min-height: inherit;
}

.anchor-section {
  position: relative;
}

.anchor-section_anchor {
  position: absolute;
  top: -60px;
}

.text-uppercase {
  text-transform: uppercase;
}

.white-bg {
  background-color: rgb(255, 255, 255);
}

/* Required to make CSE convertion blocks hidden */

/* .leadConversionWrapper {
  position: absolute;
  top: -1000px;
  left: -1000px;
} */

/* START styles for browser without Shadow DOM */

m1-find-vehicle-us h2 {
  color: var(--white-color);
}

m1-find-vehicle-us input,
m1-check-availability-form input {
  padding: 3px 10px !important;
  margin: 0 !important;
  border-color: transparent !important;
  line-height: 24px !important;
}

m1-check-availability-dialog,
m1-check-availability-response-dialog,
paper-toast {
  z-index: 999;
}

m1-check-availability-dialog,
m1-check-availability-response-dialog {
  position: relative;
}

/* m1-quote-trim-form h2 {
  color: var(--white-color);
}

m1-quote-trim-form input {
  margin: 0 !important;
  border: none !important;
} */

m1-find-vehicle-expandable-small input,
m1-find-vehicle-expandable input {
  margin: 0 !important;
  border: none !important;
}

/* END styles for browser without Shadow DOM */
.line-elps {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

@media only screen and (min-width: 1025px) {
  .msnt-show-skin--desktop .pre-center {
    max-width: 1024px;
  }
}

.root > [class*='pre-center'].post_box-top {
  padding-top: 0px !important;
}

@media only screen and (max-width: 767px) {
  body:not([data-edtn='de']) .m1-ap-native:not(.m1-ap-native-mobile),
  body:not([data-edtn='de']) .m1-ap-native:not(.m1-ap-native-mobile) .ap {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .m1-ap-native.m1-ap-native-mobile,
  .m1-ap-native.m1-ap-native-mobile .ap {
    display: none;
  }
}

@media only screen {
  /* [data-edtn='it'] #selectedTrimsTotal {
    bottom: 50px;
  } */

  body[data-edtn='de'] .ap,
  body[data-edtn='ev_de'] .ap  {
    position: relative;
  }

  body[data-edtn='de'] .apb,
  body[data-edtn='ev_de'] .apb,
  body[data-edtn='de'] .apInarticleSmallRes
  body[data-edtn='de'] .mleaderboard {
    overflow: visible;
  }

  body[data-edtn='de'] .apb,
  body[data-edtn='de'] .apInarticleSmallRes,
  body[data-edtn='de'] .mleaderboard {
    line-height: 1.1;
  }

  body[data-edtn='de'] .m1-ap-native .ap::before,
  body[data-edtn='ev_de'] .m1-ap-native .ap::before,
  body[data-edtn='de'] .lg-topside-box-wrapper-without-apb .ap::before,
  body[data-edtn='ev_de'] .lg-topside-box-wrapper-without-apb .ap::before,
  body[data-edtn='de'] .m1-header-ad .super .ap::before,
  body[data-edtn='ev_de'] .m1-header-ad .super .ap::before {
    content: '';
  }

  body[data-edtn='de'] .m1-header-ad .ap::before {
    color: white;
  }

  .sellwild-wrapper {
    margin-bottom: 25px;
  }

  .m1-header-main .m1-navigation-main > li.fr.desktop {
    display: none;
  }

  #ms-adv-overlay-box {
    z-index: 109 !important;
  }
}

@media only screen {
  #stick_side {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .headerStick #stick_side {
    display: block;
  }
}

@media only screen {
  .live-sticker .ls-logo {
    background-image: url(https://cdn.motor1.com/images/static/live.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

@media only screen and (max-width: 767px) {
  .anchor-section_anchor {
    top: -100px;
  }
}

@media only screen {
  #ap_popup_gallery_rectangle,
  #ap_popup_gallery_top {
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    text-align: center;
  }

  #ap_popup_gallery_rectangle .ap,
  #ap_popup_gallery_top .ap {
    display: inline-block;
    text-align: center;
  }

  #ap_popup_gallery_top,
  #ap_popup_gallery_top .ap,
  #ap_popup_gallery_rectangle,
  #ap_popup_gallery_rectangle .ap {
    display: none;
  }

  #ap_popup_gallery_rectangle_ry {
    margin: 0 auto;
  }
}

.resume {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .lg-topside-box .apb.rectangle,
  .lg-topside-box .apb.rectangle .ap {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .lg-topside-box .apb.mleaderboard,
  .lg-topside-box .apb.mleaderboard .ap {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .lg-topside-box .apb.super,
  .lg-topside-box .apb.super .ap {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1099px) {
  .lg-topside-box .apb.leaderboard,
  .lg-topside-box .apb.leaderboard .ap,
  .lg-topside-box .apb.super,
  .lg-topside-box .apb.super .ap {
    display: none;
  }
}

@media only screen and (min-width: 1100px) {
  .lg-topside-box .apb.mleaderboard,
  .lg-topside-box .apb.mleaderboard .ap {
    display: none;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1339px) {
  .lg-topside-box .apb.super,
  .lg-topside-box .apb.super .ap {
    display: none;
  }
}

@media only screen and (min-width: 1340px) {
  .lg-topside-box .apb.mleaderboard,
  .lg-topside-box .apb.mleaderboard .ap,
  .lg-topside-box .apb.leaderboard,
  .lg-topside-box .apb.leaderboard .ap {
    display: none;
  }
}

@media only screen and (min-width: 767px) {
  .hapb.top .apb {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .hapb.top {
    /* height:0; */
    overflow: hidden;
  }
}

.sizer {
  display: none;
}

@media only screen {
  .cat-sticker {
    text-transform: uppercase;
    padding: 0 10px !important;
    font-size: 11px !important;
    cursor: pointer !important;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery-padding {
    padding: 0 25px;
  }
}

@media only screen and (min-width: 1280px) {
  body[data-edtn='fr'] .m1-header-main .m1-navigation-main > li.fr.desktop {
    display: flex;
  }
}

@media only screen {
  .grid .item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/* @media only screen and (min-width: 768px) {
  .grid-np {
    padding: 0;
  }

  .grid-1-3 .item {
    vertical-align: top;
    display: inline-block;
    width: 33.33%;
    padding: 0 25px 25px 0;
  }

  .grid-1-2-3 .item,
  .grid-1-2-4 .item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 25px 25px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-1-2-3 .item {
    display: inline-block;
    width: 33.33%;
  }

  .grid-1-2-4 .item {
    display: inline-block;
    width: 25%;
  }
}

@media only screen {
  .sidebar .m1-find-vehicle-us.small,
  .sidebar .m1-find-vehicle-eu.small,
  .sidebar .m1-find-vehicle-expandable-small {
    margin-bottom: 20px;
  }
} */

@media only screen and (min-width: 1024px) {
  .content-area .sidebar {
    display: block;
    float: right;
    margin-left: -300px;
    width: 300px;
    transition: transform 0.5s ease;
    right: 0;
  }

  .ie_transform {
    -ms-transform: translete(0, 60px);
  }

  .sidebar .apb:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .postAuthorBox:not(.postAuthorBoxTop) {
    margin-left: 100px;
    margin-bottom: 25px;
  }

  .postAuthorBox.withAvatar {
    margin-left: 0;
  }
}

@media only screen {
  #developer {
    background: var(--admininfocolor);
    width: 16px;
    height: 16px;
    /* position: fixed; */
    top: 2px;
    right: 2px;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    color: var(--black-color);
    z-index: 9999;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  @-moz-keyframes -rblink {
    0% {
      background-color: rgba(var(--rblink-background), 0);
    }

    15% {
      background-color: rgba(var(--rblink-background), 1);
    }

    85% {
      background-color: rgba(var(--rblink-background), 1);
    }

    100% {
      background-color: rgba(var(--rblink-background), 0);
    }
  }

  @-webkit-keyframes -rblink {
    0% {
      background-color: rgba(var(--rblink-background), 0);
    }

    15% {
      background-color: rgba(var(--rblink-background), 1);
    }

    85% {
      background-color: rgba(var(--rblink-background), 1);
    }

    100% {
      background-color: rgba(var(--rblink-background), 0);
    }
  }

  @-ms-keyframes -rblink {
    0% {
      background-color: rgba(var(--rblink-background), 0);
    }

    15% {
      background-color: rgba(var(--rblink-background), 1);
    }

    85% {
      background-color: rgba(var(--rblink-background), 1);
    }

    100% {
      background-color: rgba(var(--rblink-background), 0);
    }
  }

  #developer a {
    color: var(--blue-color) !important;
  }

  #developer.alarm {
    background: rgb(var(--rblink-background));
    color: var(--white-color);
    font-weight: 600;
    -moz-animation: -rblink 2s infinite linear;
    -webkit-animation: -rblink 2s infinite linear;
    -ms-animation: -rblink 2s infinite linear;
    animation: -rblink 2s infinite linear;
  }

  #developer:hover {
    width: 300px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 10px;
    -moz-animation: none;
  }
}

@media only screen {
  .requestsProfilerBrief,
  .requestsProfilerDetail {
    display: none;
  }

  #container_3rd_party_service {
    position: relative;
  }

  #disqus_thread {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .no-apb .sidebar .apb .ap {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .requestsProfilerBrief {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 0;
    line-height: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 998;
    color: var(--white-color);
    cursor: pointer;
    background: var(--requests-profiler-brief);
    animation: live_database 2s infinite linear;
  }

  .requestsProfilerBrief.average {
    background: var(--requests-profiler-brief-average);
  }

  .requestsProfilerBrief.slow {
    background: var(--requests-profiler-brief-slow);
  }

  .requestsProfilerBrief:hover {
    font-size: 14px;
    line-height: 1.2;
    min-width: 250px;
    width: auto;
    height: auto;
    padding: 10px;
    border-radius: 5px;
    animation: none;
  }

  .cmpDialog.profiler .box {
    max-height: 90%;
    width: 90%;
    max-width: none;
    overflow: auto;
  }

  .cmpDialog.profiler .box .content {
    overflow: auto;
  }

  .cmpDialog.profiler .profile-item {
    transition: background 0.3s linear;
  }

  .cmpDialog.profiler .profile-item + .profile-item {
    border-top: 1px solid #ccc;
  }

  .cmpDialog.profiler .profile-item.open {
    background: var(--cmp-dialog-open);
  }

  .cmpDialog.profiler h3 {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    text-transform: none;
  }

  .cmpDialog.profiler h3 span {
    display: inline-block;
    vertical-align: middle;
  }

  .cmpDialog.profiler h3 .copy-raw {
    float: right;
  }

  .cmpDialog.profiler h3 .copy-raw:hover {
    position: relative;
    color: var(--cmpdialog-color);
  }

  .cmpDialog.profiler h3 .copy-raw:active {
    top: 1px;
    left: 1px;
  }

  .cmpDialog.profiler h3 .copy-raw textarea {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .cmpDialog.profiler .status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .cmpDialog.profiler .status.fast {
    background: var(--requests-profiler-brief);
  }

  .cmpDialog.profiler .status.average {
    background: var(--requests-profiler-brief-average);
  }

  .cmpDialog.profiler .status.slow {
    background: var(--requests-profiler-brief-slow);
  }

  .cmpDialog.profiler .details {
    width: 100%;
    overflow-x: auto;
    display: none;
  }

  .cmpDialog.profiler .details ol {
    padding-left: 35px;
  }

  .cmpDialog.profiler .details li {
    list-style-type: decimal;
  }

  .cmpDialog.profiler th,
  .cmpDialog.profiler td {
    width: 50%;
    padding: 5px;
  }

  .cmpDialog.profiler .box .content {
    padding: 0;
  }

  .cmpDialog.profiler .box .content pre {
    background-color: transparent !important;
  }

  .cmpDialog.profiler .box .close {
    display: none;
  }
}

@keyframes live_database {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen {
  .disqus-comment-count.light {
    border-color: var(--white-color) !important;
    color: var(--white-color) !important;
  }
  a.disqus-comment-count_wrapper:hover {
    text-decoration: none;
    color: var(--main-text-color-special);
    border-color: var(--main-text-color-special);
  }

  a.disqus-comment-count_wrapper.light:hover {
    color: var(--white-color) !important;
  }

  .disqus-comment-count_wrapper .value {
    font-weight: 600;
  }

  .msnt-show-skin--desktop .ready-center,
  .msnt-show-skin--phone .ready-center {
    background: var(--main-bg-color);
  }
}

/* @media only screen {
  [data-bubbles-target] {
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    z-index: 9999;
    opacity: 0;
  }

  [data-bubbles-target].disabled {
    left: -1000px;
    top: -1000px;
  }

  [data-bubbles-target].enabled.show {
    opacity: 1;
  }

  [data-bubbles-target] .bubbles-box {
    background: var(--white-bg-color);
    border: 5px solid #d9d9d9;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  [data-bubbles-target] .bubbles-box .image-box {
    font-size: 0;
    line-height: 0;
    display: block;
  }

  [data-bubbles-target]::before {
    content: '';
    width: 0;
    height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    border: solid;
  }

  [data-bubbles-target][data-pos='1'] {
    margin-left: -20px;
    padding-top: 20px;
  }

  [data-bubbles-target][data-pos='1']::before,
  [data-bubbles-target][data-pos='2']::before {
    border-width: 0 10px 10px;
    border-color: transparent transparent var(--data-bubbles-target-color);
    top: 10px;
    left: 10px;
  }

  [data-bubbles-target][data-pos='3'] {
    margin-left: -20px;
    padding-bottom: 20px;
  }

  [data-bubbles-target][data-pos='3']::before,
  [data-bubbles-target][data-pos='4']::before {
    border-width: 10px 10px 0;
    border-color: var(--data-bubbles-target-color) transparent transparent;
    bottom: 10px;
    left: 10px;
  }

  [data-bubbles-target][data-pos='2'] {
    padding-top: 20px;
    margin-left: 30px;
  }

  [data-bubbles-target][data-pos='4'] {
    padding-bottom: 20px;
    margin-left: 30px;
  }

  [data-bubbles-target][data-pos='4']::before,
  [data-bubbles-target][data-pos='2']::before {
    left: auto;
    right: 10px;
  }
} */

/* ############################################################################################### */

@media only screen and (max-width: 767px) {
  .b-padding-skin {
    padding-bottom: 0;
  }

  .msnt-show-skin--phone .ready-center {
    z-index: 10;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .msnt-show-skin--phone .ready-center.light {
    background: var(--white-bg-color);
  }

  .msnt-show-skin--phone .pageAdSkin {
    display: none;
    position: relative;
  }

  .msnt-show-skin--phone .pageAdSkin .pageAdSkinUrl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 100%;
  }

  .msnt-show-skin--phone .m1-header-ad .m1-gapb-topside .m1-apb.mleaderboard {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .msnt-show-skin--phone .pageAdSkin .adSizer img {
    width: 50%;
    display: block;
  }

  body[data-edtn='it'].msnt-show-skin--phone .pageAdSkin .adSizer {
    padding: 0;
  }
}

@media only screen and (min-width: 415px) and (max-width: 767px) {
  .msnt-show-skin--phone .pageAdSkin .adSizer img {
    width: 12.5%;
    display: block;
  }

  body[data-edtn='it'].msnt-show-skin--phone .pageAdSkin .adSizer {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .msnt-show-skin--pad .ready-center {
    z-index: 10;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: var(--main-bg-color);
  }

  .msnt-show-skin--pad .ready-center.light {
    background: var(--white-bg-color);
  }

  .msnt-show-skin--pad .pageAdSkin {
    position: relative;
    z-index: 1 !important;
  }

  @media (orientation: landscape) {
    .msnt-show-skin--pad .pageAdSkin .adSizer img {
      width: 12.5%;
      display: block;
    }
  }

  @media (orientation: portrait) {
    .msnt-show-skin--pad .pageAdSkin .adSizer img {
      width: 50%;
      display: block;
    }
  }

  .msnt-show-skin--pad .pageAdSkin .pageAdSkinUrl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1000px;
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 100%;
  }

  .msnt-show-skin--pad .m1-header-ad .m1-gapb-topside .m1-apb.leaderboard {
    display: none;
  }

  .msnt-show-skin--pad .root > div[class*='pre-center'] {
    position: relative;
    z-index: 3;
  }

  .msnt-show-skin--pad .root > [class*='pre-center'].top:not(.ntr) {
    background: none;
  }

  .msnt-show-skin--pad .root > [class*='pre-center'].top:not(.ntr) .content-header {
    background: rgba(var(--black), 0.4);
    padding: 25px;
  }

  .msnt-show-skin--pad .root > [class*='pre-center'].top:not(.ntr) .content-header h1 {
    margin: 0;
    color: var(--white-color);
  }

  .msnt-show-skin--pad .car-specs-box,
  .msnt-show-skin--pad .breadcrumbs-box:not(.ntr) {
    background: rgba(var(--black), 0.4);
  }

  .msnt-show-skin--pad .breadcrumbs-box:not(.ntr) .breadcrumbs,
  .msnt-show-skin--pad .breadcrumbs-box:not(.ntr) .breadcrumbs > a,
  .msnt-show-skin--pad .breadcrumbs-box:not(.ntr) .breadcrumbs > .sep::after {
    color: var(--white-color);
  }
}

@media only screen and (min-width: 1025px) {
  .msnt-show-skin--desktop .ready-center {
    min-width: 1024px;
    max-width: 1024px;
    width: 75%;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  .msnt-show-skin--desktop .b-padding-skin {
    padding-bottom: 0;
  }

  .msnt-show-skin--desktop .ready-center [class*='pre-center'] {
    width: 100% !important;
  }

  body:not(.m1-fullwidth-layout) .ready-center.light,
  .msnt-show-skin--desktop .ready-center.light {
    background: var(--white-bg-color);
  }

  .msnt-show-skin--desktop .pageAdSkin {
    position: relative;
    z-index: 1 !important;
  }

  .msnt-show-skin--desktop .m1-header-ad .m1-gapb-topside .m1-apb.super {
    display: none;
  }

  .msnt-show-skin--desktop .root {
    height: 100%;
  }

  .msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: inherit;
    background-repeat: no-repeat;
    background-position: center 0;
  }

  .msnt-show-skin--stick.msnt-show-skin--desktop .pageAdSkin {
    position: static;
  }

  .msnt-show-skin--stick.msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl {
    height: auto;
    bottom: 0;
  }

  .msnt-show-skin--desktop .hapb.top {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .msnt-show-skin--fixed.msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl {
    background-attachment: fixed;
    position: fixed;
    height: 100vh;
    background-position: center 83px;
  }

  body[data-edtn='tr'].msnt-show-skin--fixed.msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl {
    background-position: center 142px;
  }

  body[data-edtn='ev_it'].msnt-show-skin--fixed.msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl {
    background-position: center 126px;
  }

  .msnt-show-skin--fixed.msnt-show-skin--desktop.headerStick-hide .pageAdSkin .pageAdSkinUrl {
    background-position: center 0;
    -webkit-transition: background-position-y 0.3s linear;
    transition: background-position-y 0.3s linear;
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
  }

  body[data-edtn='ev_it'].msnt-show-skin--fixed.msnt-show-skin--desktop.headerStick-hide .pageAdSkin .pageAdSkinUrl,
  body[data-edtn='ev_tr'].msnt-show-skin--fixed.msnt-show-skin--desktop.headerStick-hide .pageAdSkin .pageAdSkinUrl {
    background-position: center 0;
    -webkit-transition: background-position-y 0.3s linear;
    transition: background-position-y 0.3s linear;
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
  }

  body[data-edtn='de'] .root > div[class*='pre-center'],
  body[data-edtn='fr'] .root > div[class*='pre-center'],
  body[data-edtn='it'] .root > div[class*='pre-center'],
  .msnt-show-skin--desktop .root > div[class*='pre-center'] {
    position: relative;
    z-index: 3;
  }

  .msnt-show-skin--desktop .root > [class*='pre-center'].top:not(.ntr) {
    background: none;
  }

  .msnt-show-skin--desktop .root > [class*='pre-center'].top:not(.ntr) .content-header {
    background: var(--white-article-bg);
  }

  .msnt-show-skin--desktop .root > [class*='pre-center'].top .content-headerr {
    padding: 25px;
  }

  .msnt-show-skin--desktop .root > [class*='pre-center'].top:not(.ntr) .content-header h1 {
    margin: 0;
    color: var(--main-text-color-special);
  }

  .msnt-show-skin--desktop .car-specs-box,
  .msnt-show-skin--desktop .breadcrumbs-box:not(.ntr) {
    background: var(--white-article-bg);
  }
}

@media only screen and (min-width: 1025px) {
  /* (min-width: 1700px) */
  .msnt-show-skin--desktop .pageAdSkin .adSizer {
    height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .mobile-adv {
    display: none;
  }
}

/* END trending content */

@media only screen {
  body {
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .m1-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .m1-scrollbar::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  .m1-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--m1-scrollbar);
  }

  .m1-scrollbar-hide::-webkit-scrollbar {
    width: 0;
  }
}

@media only screen {
  #empty {
    min-height: 300px;
  }
}

@media only screen and (min-width: 1025px) {
  .msnt-show-skin--desktop .m1-center {
    max-width: 1024px;
    min-width: 1024px;
    width: 75%;
  }
}

@media only screen {
  .entityInfoBlock .sourceLogo {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }

  .entityInfoBlock .sourceLogo img {
    height: 35px;
  }

  .entityInfoBlock .entity + .entity {
    margin-top: 5px;
  }
}

/* Skin Blend 5131 */

@media only screen and (max-width: 1024px) {
  .msnt-show-skin--phone .m1-header-main,
  .msnt-show-skin--pad .m1-header-main {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  }

  .msnt-show-skin--phone .pageAdSkin,
  .msnt-show-skin--pad .pageAdSkin {
    overflow: hidden;
  }

  .msnt-show-skin--phone .pageAdSkin::after,
  .msnt-show-skin--pad .pageAdSkin::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 1em;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
}

#page_index_index_upload .bevel {
  padding-left: 0;
}

#page_index_index_upload .bevel #upload_form label input[name="photo"] {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 5px;
}

@media only screen and (max-width: 767px) {
  .lg-topside-box .adgrid-ad-container .adv-ad-wrapper {
    padding: 10px 0;
  }
}

#page_index_index_gebrauchtwagen  .adv_container{
  display: none;
}

.grecaptcha-badge {
  z-index: 99;
}