@media only screen {
    .m1-footer-messages {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        /* z-index: 110; */
        z-index: 3000000;
    }
    .m1-footer-message {
        background: var(--trending-content-link);
        color: var(--white-color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: transform 0.5s ease;
        padding: 15px;
    }
    .m1-footer-message a {
        color: var(--white-color);
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid var(--main-link-color);
    }
    .m1-footer-message a:hover {
        text-decoration: none;
        border-color: transparent;
        color: var( --main-link-color);
    }
    .m1-footer-message.active {
        transform: translate(0, -100%);
    }
    .m1-footer-message-button {
        color: var(--white-color);
        cursor: pointer;
        display: inline-block;
        padding: 7px 15px;
        line-height: 1;
        border-radius: 3px;
        font-size: 12px;
        white-space: nowrap;
        background: var(--main-link-color);
        text-decoration: none;
        vertical-align: middle;
    }
    .m1-footer-message-button-box {
        margin-top: 15px;
        text-align: center;
        white-space: nowrap;
    }
    .m1-footer-message-text {
        font-size: 14px;
        line-height: 1.5;
    }
    .m1-footer-message-button-close {
        position: relative;
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }
    .m1-footer-message-button-close:before,
    .m1-footer-message-button-close:after {
        width: 23px;
        height: 3px;
        position: absolute;
        left: 3px;
        top: 14px;
        content: '';
        background: var(--white-bg-color);
        border-radius: 3px;
    }
    .m1-footer-message-button-close.arrow-left:before {
        left: 9px;
        width: 18px;
        top: 24px;
    }
    .m1-footer-message-button-close.arrow-left:after {
        left: 9px;
        width: 18px;
        top: 13px;
    }
    .m1-footer-message-button-close:before {
        transform: rotate(45deg);
    }
    .m1-footer-message-button-close:after {
        transform: rotate(-45deg);
    }
}

@media only screen and (min-width: 480px) {
    .m1-footer-message-wrapper {
        display: table;
        width: 100%;
        max-width: 1800px;
        margin: 0 auto;
    }
    .m1-footer-message-text {
        display: table-cell;
        vertical-align: middle;
    }
    .m1-footer-message-button-box {
        display: table-cell;
        vertical-align: middle;
        margin-top: 0;
        padding-left: 15px;
    }
    .m1-footer-message-button {
        padding: 10px 20px;
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .m1-footer-message {
        padding: 20px 30px;
    }
    .m1-footer-message-text {
        font-size: 15px;
    }
    .m1-footer-message-button-box {
        padding-left: 30px;
        text-align: right;
    }
}

@media only screen and (min-width: 1024px) {
    .m1-footer-message {
        padding: 20px 50px;
    }
    .m1-footer-message-text {
        font-size: 16px;
    }
}