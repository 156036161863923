.select2-hidden-accessible {
  display: none;
}

.select2-container {
  background: rgb(var(--vamc-fill-soft-100));
  box-sizing: border-box;
  font-weight: var(--vam-comfort-label-medium-font-weight);
  font-size: var(--vam-comfort-label-medium-font-size);
  line-height: var(--vam-comfort-label-medium-font-line-height);
  font-family: var(--vam-comfort-label-medium-font-name);
  letter-spacing: var(--vam-comfort-label-medium-font-tracking);
  direction: ltr;
}

.select2-container.select2-container--open {
  position: relative;
  z-index: 9;
}

.select2 {
  flex: 1 1 auto;
  width: 100% !important;
  display: flex;
  align-items: center;
  height: 52px;
  color: rgb(var(--vamc-text-contrast-soft));
  text-transform: uppercase;
  border: 1px solid rgb(var(--vamc-stroke-soft-300));
  border-radius: 6px;
  padding: 6px;
  padding-inline-start: 16px;
  cursor: pointer;
}


.select2-container--disabled {
  cursor: auto;
  visibility: hidden;
}

.select2-container .selection {
  width: 100%;
} 

.select2-selection  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 8px;
  text-transform: none;
}

.select2-selection__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgb(var(--vamc-fill-soft-300));
  border-radius: 4px;
}

.select2-selection__arrow:before {
  content: "";
  background-color: rgb(var(--vamc-text-contrast-soft));
  -webkit-mask-image: var(--m1-icon-arrow-usage);
  mask-image: var(--m1-icon-arrow-usage);
  width: 19px;
  height: 100%;
  display: block;
  transform: rotate(90deg) /*!rtl:ignore*/;
  transition: transform .1s ease;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgb(var(--vamc-fill-soft-300));
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgb(var(--vamc-fill-soft-200));
  border-radius: 3px;
}

.select2-results {
  display: block;
}

.select2-results__option {
  padding: 6px;
  margin: 0 5px;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-dropdown {
  display: block;
  border: 1px solid rgb(var(--vamc-stroke-soft-200));
  direction: ltr;
}

.select2-search--dropdown {
  display: block;
  padding: 6px;
  position: relative;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  margin: 0 !important;
}

.select2-search__field {
  padding-inline-end: 20px !important;
  font-size: var(--vam-comfort-label-medium-font-size) !important;
  text-transform: none !important;
  min-height: 30px;
}

.select2-search--dropdown:after {
  content: "";
  background-color: var(--main-text-color-special);
  -webkit-mask-image: var(--m1-icon-search-usage);
  mask-image: var(--m1-icon-search-usage);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 16px;
  height: 16px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  cursor: default;
}

input.select2-container[type='submit'] {
  margin: 0 !important;
  background-color: rgba(var(--vamc-button-accent-solid-calm-fill), 0.15) !important;
  color: rgba(var(--vamc-button-accent-solid-calm-text)) !important;
  height: 52px;
  border-radius: 4px !important;
  padding: 0 22px !important;
  min-width: 180px !important;
  border: none !important;
  cursor: pointer !important;
  transition: background 0.15s linear !important;
}

input.select2-container[type='submit']:hover {
  background-color: rgba(var(--vamc-button-accent-solid-calm-fill), 0.25) !important;
}

input.select2-container[type='submit'][disabled] {
  visibility: hidden !important;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

@media screen and (max-width: 767px) {
  .select2-container--disabled,
  input.select2-container[type='submit'][disabled] {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .select2 {
    max-width: 320px;
  }
}